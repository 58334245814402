// ----------------------------------------------------------------------
const name = localStorage.getItem('name');
const email = localStorage.getItem('email');
const txn = localStorage.getItem('txn');
const account = {
  displayName: name,
  email,
  txn,
  // photoURL: 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.exscribe.com%2Fmodmed%2Fattachment%2Fplaceholder-image-person-jpg&psig=AOvVaw2c2WHWSYfMzvMOUNQf3Faw&ust=1695046381790000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCIjY2oLrsYEDFQAAAAAdAAAAABAI',
};

export default account;
